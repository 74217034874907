<template>
  <div class="Zixun_C">
    <p class="Zixun_Ctitle">{{ data.title }}</p>
    <ul class="Zixun_Cul">
      <li>阅读：{{ data.onclick }}</li>
      <li>点赞：{{ data.give_like == null ? "0" : data.give_like }}</li>
      <li>{{ data.create_time }}</li>
    </ul>
    <div class="Zixun_Cbox" v-html="data.newstext"></div>
    <Sjpinglun :all="pinglunall" />
    <zan :all="zanall" />
  </div>
</template>
<script>
import zan from "../components/zan";
import Sjpinglun from "../components/Sjpinglun";
export default {
  components: {
    zan,
    Sjpinglun,
  },
  data() {
    return {
      pinglunall: {
        classid: "8",
        id: "",
      },
      id: "",
      data: "",
      userinfo: {},
      zanall: {
        classid: "8",
        id: "0",
      },
    };
  },
  methods: {},
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    var id = that.$route.query.data;
    that.zanall.id = id;
    that.pinglunall.id = id;
    that
      .$axios({
        url: "/api/getNewsDetails",
        method: "post",
        data: {
          id,
        },
      })
      .then((res) => {
        if (res.data.code == 1) {
          that.data = res.data.result;
          document.title = res.data.result.title;
          this.fenxiang(
            res.data.result.title,
            res.data.result.smalltext,
            res.data.result.titlepic
          );
        } else {
          console.log("请求失败");
        }
      });
  },
};
</script>
<style>
@import "../assets/css/zixun.css";
</style>
